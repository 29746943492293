/// <reference path="bootstrap.less" />


@font-face {
    font-family: 'signpainter_housescriptRg';
    src: url('fonts/ufonts.com_signpainter-housescript-webfont.eot');
    src: url('fonts/ufonts.com_signpainter-housescript-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/ufonts.com_signpainter-housescript-webfont.woff2') format('woff2'),
         url('fonts/ufonts.com_signpainter-housescript-webfont.woff') format('woff'),
         url('fonts/ufonts.com_signpainter-housescript-webfont.ttf') format('truetype'),
         url('fonts/ufonts.com_signpainter-housescript-webfont.svg#signpainter_housescriptRg') format('svg');
    font-weight: normal;
    font-style: normal;

}