.backoffice {
    header {
        .header-bottom.dashboard {
            background: #fff;
        }
    }

    .bar-backoffice {
        background-color: @pink;
        height: 54px;
        border-bottom: 4px solid @brown;
        margin-bottom: 50px;
        position: absolute;
        width: 100%;
        top: 67px;
        z-index: -10;


        @media(max-width: @screen-sm-max) {
            top: 151px;
        }
    }

    .container-backoffice-menu {
        margin-bottom: 20px;
        z-index: 40;
    }

    .navbar {
        li {
            a {
                color: #fff;

                &:hover {
                    color: @brown;
                }
            }



            &.active {
                a {
                    color: #fff;
                    background: @brown;

                    &:hover {
                        color: #fff;
                        background: @brown;
                        cursor: default;
                    }
                }
            }
        }

        @media(max-width: @screen-xs-max) {
            background: @pink;
            border-radius: 4px;

            ul {
                background: none;
                border: 0;
            }
        }
    }
}


#vat-calculator {
    position: absolute;
    background: #fff;
    border: 1px solid #ddd;
    z-index: 1;
    margin-left: 10px;
    padding: 10px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
    border-radius: 4px;
    text-align: center;

    > ul {
        margin: 10px 0;
        padding: 0;

        > li {
            display:inline-block;
        }
    }

    &:after, &:before {
        bottom: 100%;
        left: 10%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    &:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #fff;
        border-width: 6px;
        margin-left: -6px;
    }

    &:before {
        border-color: rgba(221, 221, 221, 0);
        border-bottom-color: #ddd;
        border-width: 7px;
        margin-left: -7px;
    }


    input {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        text-align: left;

        &:focus {
            opacity: 1;
        }
    }

    label {
        font-weight: normal;
    }

    .vat-calculator-input {
        position: relative;
    }
}