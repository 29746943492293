/// <reference path="bootstrap.less" />
.action-menu {
    background-color: #f5f5f5;
    border-radius: 4px;
    padding: 20px;

    h3 {
        margin-bottom: 10px;
    }
}
