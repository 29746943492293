/// <reference path="bootstrap.less" />
.product-popup {
    background: @white;
    color: @black;
    padding: 0;
    font-style: italic;
    border-radius: 30px 40px 32px 40px;
    overflow: hidden;


    h3 {
        font-weight: bold;
        background: @pink;
        color: #fff;
        padding: 15px;
        border-radius: 30px 30px 0 0;
    }


    .modal-body {
        padding: 0;

        .fa-remove {
            padding: 17px;
            opacity: 1;
        }
    }

    .row.row-popup {
        padding-left: 15px;
        padding-right: 15px;
    }

    .slider {
        display: block;
        height: 250px;
        min-width: 260px;
        max-width: 640px;
        margin: auto;
        position: relative;
        margin-bottom: 30px;
    }

    .sliderinner {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
        user-select: none;
    }

    .sliderinner > ul {
        padding: 0;
        list-style: none;
        height: 100%;
        width: 500%;
        overflow: hidden;
        position: relative;
        left: 0;

        &.animating {
            -webkit-transition: left .8s cubic-bezier(.36,.09,0,.92);
            -moz-transition: left .8s cubic-bezier(.36,.09,0,.92);
            -o-transition: left .8s cubic-bezier(.36,.09,0,.92);
            transition: left .8s cubic-bezier(.36,.09,0,.92);
        }
    }

    .sliderinner > ul > li {
        width: 20%;
        height: 250px;
        float: left;
        position: relative;
        text-align: center;
    }

    .sliderinner > ul > li > img {
        margin: auto;
    }

    .slider input[type=radio] {
        position: absolute;
        left: 50%;
        bottom: 15px;
        z-index: 100;
        visibility: hidden;
    }

    .slider label {
        position: absolute;
        left: 50%;
        bottom: -25px;
        z-index: 100;
        width: 12px;
        height: 12px;
        background-color: #ccc;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        cursor: pointer;
    }

    .slider input[type=radio]#image1:checked ~ label[for=image1] {
        background-color: #E6007E;
    }

    .slider input[type=radio]#image2:checked ~ label[for=image2] {
        background-color: #E6007E;
    }

    .slider input[type=radio]#image3:checked ~ label[for=image3] {
        background-color: #E6007E;
    }

    .slider input[type=radio]#image4:checked ~ label[for=image4] {
        background-color: #E6007E;
    }

    .slider input[type=radio]#image5:checked ~ label[for=image5] {
        background-color: #E6007E;
    }

    .slider label[for=image1] {
        margin-left: -36px;
    }

    .slider label[for=image2] {
        margin-left: -18px;
    }

    .slider label[for=image4] {
        margin-left: 18px;
    }

    .slider label[for=image5] {
        margin-left: 36px;
    }

    .slider input[type=radio]#image1:checked ~ .sliderinner > ul {
        left: 0;
    }

    .slider input[type=radio]#image2:checked ~ .sliderinner > ul {
        left: -100%;
    }

    .slider input[type=radio]#image3:checked ~ .sliderinner > ul {
        left: -200%;
    }

    .slider input[type=radio]#image4:checked ~ .sliderinner > ul {
        left: -300%;
    }

    .slider input[type=radio]#image5:checked ~ .sliderinner > ul {
        left: -400%;
    }

    hr {
        background: url(../Content/images/border-white.png) repeat-x;
        height: 10px;
        border: 0;
    }


    hr.brown {
        background: url(../Content/images/border-brown.png) repeat-x;
        height: 10px;
        border: 0;
        opacity: 0.2;
    }



    .product-count {
        display: inline-block;
        width: 20px;
        text-align: center;
    }

    .int-up-down {
        .user-select(none);

        a {
            .user-select(none);
        }
    }

    @media (min-width: @screen-md-min) {
        .modal-footer {
            .form-group {
                margin-bottom: 0;
            }

            .int-up-down {
                display: inline-block;
                margin-left: 10px;
            }

            input.btn {
                margin-top: -4px;
                margin-left: 15px;
            }

            .form-group-count {
                margin-top: 6px;
            }

            .form-group-product-option {
                margin-top: -2px;

                .form-control {
                    width: 75%;
                    display: inline;
                }
            }
        }
    }
    /*
    @media (max-width: @screen-sm-max) {
        .modal-footer {
            input.btn {
                margin-top: 16px;
            }
        }
    }*/
    h3 {
        font-weight: bold;
    }

    .price {
        color: @pink;
        font-size: 28px;
        font-weight: bold;
        margin-top: -4px;
        /*@media (min-width: @screen-md-min) {
            line-height: 84px;
            text-align: right;
        }*/
    }
}
