/// <reference path="bootstrap.less" />
html.fixed-footer-small {
    position: relative;
    min-height: 100%;

    body {
        margin-bottom: 110px;

        footer {
            padding-top: 40px;
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 110px;
            overflow: hidden;
        }
    }
}

footer {
    background: url(../Content/images/bg-wood-white.jpg) repeat-x;


    & .footer-top {
        display: block;
        background: #fff;
        margin-bottom: 37px;
        padding: 65px 0;
        color: @brown;
        font-style: italic;
        line-height: 22px;

        & a {
            color: @brown;
        }

        & h1,
        & h2,
        & h3 {
            color: @pink;
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 12px;
        }

        & .menu {
            & a {
                padding: 0;

                &:hover {
                    background: 0 none;
                    color: @pink;
                    font-weight: bold;
                }
            }

            > .active > a {
                &,
                &:hover,
                &:focus {
                    color: @navbar-default-link-active-color;
                    background-color: @navbar-default-link-active-bg;
                }
            }
        }
    }

    & .footer-bottom {
        display: block;
        background: @brown;
        color: #fff;
        font-size: 14px;
        padding: 25px 0;
    }
}
