/// <reference path="bootstrap.less" />
.concept {
    padding-bottom: 30px;
    font-weight: bold;
    font-size: 18px;

    img {
        clear: both;
        max-width: 100%;
    }

    h1 {
        margin-bottom: 15px;
    }

    .icon {
        @media (min-width: @screen-md-min) {
            &:after {
                position: absolute;
                right: 0;
                content: "+";
                top: 50%;
                font-size: 30px;
                margin-top: -40px;
            }
        }

        &:last-child:after {
            display: none;
        }

        @media (max-width: @screen-sm-min) {
            text-align: left;
            margin-bottom: 15px;
            padding-bottom: 15px;
        }
    }

    @media (max-width: @screen-sm-min) {
        text-align: left;

        img {
            max-width: 20%;
        }
    }
}

.icon-row {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: solid 1px #eee;

    img {
        max-width: 100%;
    }
}
