/// <reference path="bootstrap.less" />
.cart {
    & a {
        color: #fff;

        i {
            padding: 5px;
            width: 24px;
            height: 24px;
            text-align: center;
            border: 1px solid #ccc;
            border-radius: 50%;
        }
    }

    .cart-amount {
        display: inline-block;
        padding: 0 15px;
        margin: 2px 5px;
    }
}

.checkout {
    h3 {
        margin-bottom: 15px;
    }
}

.checkout-block {
    padding: 15px;
    border: 10px solid #F6F3EC;
}

#calendar-day-picker {
    overflow: hidden;
    display: table;
    width: 100%;

    & > span {
        display: table-cell;
        line-height: 34px;
        text-align: center;
        border-radius: 26px 25px 17px 23px;
        color: #ddd;

        &.available {
            cursor: pointer;
            color: inherit;

            &:hover {
                color: #000;
            }

            &.active {
                background-color: @brown;
                color: #fff;
            }
        }
    }
}

.calendar-month {
    text-align: center;
    font-weight: bold;
}

.calendar {
    display: table;
    table-layout: fixed;
    width: 100%;

    .row {
        display: table-row;

        .item {
            width: 100%;
            text-align: center;
            display: table-cell;
            line-height: 30px;

            &.item-today {
                font-weight: bold;
            }

            &.item-active {
                color: @pink;
                font-weight: bold;
            }
        }
    }
}

.checkout-text-height {

    @media(min-width: @screen-md-min) {
        min-height: 280px;
    }

    @media(min-width: @screen-lg-min) {
        min-height: 220px;
    }
}
