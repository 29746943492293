/// <reference path="bootstrap.less" />
.social-media {
    position: relative;
}

.nav.social-media {
    & a {
        border-radius: 11px 16px 16px 18px;
        background: @brown;
        width: 30px;
        height: 27px;
        color: #fff;
        text-align: center;
        padding: 3px 0;
        margin-bottom: 10px;
        line-height: 22px;

        &:hover {
            background: @pink;
        }
    }
}

.menu-top {
    & .nav.social-media {
        & a {
            line-height: 26px;
        }
    }
}

.border-line {
    display:block;
    width: 20px;
    height:2px;
    background: @brown;
    -ms-transform: rotate(65deg); /* IE 9 */
    -webkit-transform: rotate(65deg); /* Safari */
    transform: rotate(65deg);
    position: relative;
    top: 5px;
    left: 28px;
}