/// <reference path="bootstrap.less" />
.main-content {
    background: url(../Content/images/bg-wood-white.jpg) repeat-x;
    color: @brown;
    font-style: italic;


    & .main-content-top {
        padding: 45px 0;
        background: #fff;
        text-align: right;


        @media(max-width: @screen-sm-max) {
            text-align: center;
        }

        & h1 {
            font-weight: bold;
            font-size: 32px;
            margin-bottom: 10px;

            @media(max-width: @screen-md-max) {
                font-size: 28px;
            }

            @media(max-width: @screen-sm-max) {
                font-size: 32px;
            }
        }

        & h2 {
            font-size: 34px;

            @media(max-width: @screen-md-max) {
                font-size: 26px;
            }

            @media(max-width: @screen-sm-max) {
                font-size: 28px;
            }
        }
    }

    & .main-content-bottom {
        padding: 75px 0 0;
    }
}


.content-block-home {
    margin-bottom: 100px;

    img {
        max-width: 100%;
    }

    & h3 {
        font-family: @font-family-handwriting;
        font-size: 28px;
        margin-top: 15px;
        font-style: normal;
    }
}
