.statistics {

    li {
        float: left;
        display: block;
        list-style: none;
        text-align: center;
        border: #f0f0ed solid 3px;
        border-right: 0;
        background: #F5F5F5;

        .badge {
            display: block;
            clear: both;
            background: 0 none;
            color: @brown;
            
            font-size: 1.8em;
        }

        &:last-child {
            border-right: #f0f0ed solid 3px;
        }
    }
}
