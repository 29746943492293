/// <reference path="bootstrap.less" />
/*------------ Faq -----------------*/

.faq {
    dl {
	    border-bottom: 1px solid #C9C2C0;	
	    padding: 0 0 10px;
	    margin: 10px 0;
    }

    dt {
	    color: #575756;
	    cursor: pointer;
	    font-weight: bold;
	    padding: 0;
	    line-height: 21px;
    }

    dd {
	    display:none;
	    margin: 0 0 10px;
    }

    h3 {
        color: @pink;
    }
}