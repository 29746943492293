/// <reference path="../bootstrap.less" />
.cropper-modal {
  background-color: #000;
  filter: alpha(opacity=20); 
  opacity: .2;
}

.cropper-view-box {
  outline: 1px solid @pink;
  outline-color: opacity(@pink, .75);
}
.cropper-line,
.cropper-point,
.cropper-point.point-se:before {
  background-color: @pink;
}
