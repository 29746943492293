/// <reference path="bootstrap.less" />
.container.cart {
    background: @brown;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 30px 40px 32px 40px;

    hr {
        background: url(../Content/images/border-white.png) repeat-x;
        height: 10px;
        border: 0;
    }

    a {
        i {
            border: 0;
        }
    }
}

table.cart {
    tr {

        &:nth-child(even) td {
            background: #6e4c3a;
        }

        td {
            color: #fff;
            font-style: italic;
            border-top: 0;
            vertical-align: middle;
        }
    }
}

#cart {
    margin-bottom: 40px;
}

#no-cart {
    background: @brown;
    border-radius: 30px 40px 32px 40px;
    padding: 15px;
    margin-bottom: 40px;
    color: #fff;
}
