/// <reference path="bootstrap.less" />
.message-container {

    padding-bottom: 35px;

    & .message {
        border-radius: 25px;


        h1 {
            margin-bottom: 5px;
        }
    }

    form {
        margin-top: 10px;

        & .btn {
            margin-top: -5px
        }
    }
}
