/// <reference path="bootstrap.less" />
.dashboard-content {
    padding-bottom: 40px;
    min-height: 500px;

    & .title-page {
        background: @pink;
        padding-top: 5px;
        margin-bottom: 40px;

        & h1 {
            color: #fff;
            font-family: @font-family-handwriting;
            font-size: 47px;
        }
    }


    & .content-left {
        & h3 {
            color: @pink;
            font-family: @font-family-handwriting;
            font-size: 32px;
        }

        & ul {
            padding-left: 0;
            list-style: none;

            & li {
                border-radius: 8px;
                padding: 1px 5px;

                & a {
                    color: @brown;
                    font-style: italic;
                    display: block;
                    width: 100%;
                }


                &.active {
                    background: @brown;

                    & a {
                        color: #fff;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        .category {
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid #eee;

            h3 i.fa {
                display: none;
            }
        }

        @media (max-width: @screen-xs-max) {

            & {
                border-bottom: 1px solid #eee;
                margin-bottom: 10px;
            }

            & > .category {
                border-bottom: 0;
                margin-bottom: 0;

                h3 i.fa {
                    display: inline-block;

                    .transition-transform("rotate");
                    .transition-duration(.2s);
                    .transition-timing-function(ease);

                    .fa-icon-rotate(0deg, 0);
                }

                ul {
                    max-height: 0;
                    overflow: hidden;

                    .transition("max-height");
                    .transition-duration(.1s);
                    .transition-timing-function(linear);
                }

                &.open {
                    ul {
                        max-height: 200px;
                    }

                    h3 i.fa {

                        .fa-icon-rotate(90deg, 1);
                    }
                }
            }
        }
    }

    & .content-right {

        & .order-summary {
            background: @brown;
            color: #fff;
            padding: 15px;
            font-style: italic;
            border-radius: 30px 40px 32px 40px;
            cursor: pointer;

            &:hover {
                h4, p {
                    text-decoration: none;
                }
            }

            ul {
                padding-left: 0;

                li {
                    list-style: none;
                    border-radius: 30px 40px 32px 40px;
                    padding: 2px 5px;

                    &:nth-child(even) {
                        background: #6e4c3a;
                    }

                    & a {
                        color: #fff;

                        &:hover {
                            text-decoration: none;
                            color: @pink;
                        }
                    }
                }
            }

            & h4 {
                font-weight: bold;
                margin-bottom: 5px;
            }

            hr {
                background: url(../Content/images/border-white.png) repeat-x;
                height: 10px;
                border: 0;
            }

            .total-price {
                font-size: 18px;
            }
        }

        & .special-offer {
            background: #f7f5f4;
            border-radius: 30px 40px 32px 40px;
            padding: 15px;
            margin-bottom: 40px;
            color: @brown;

            & img {
                max-width: 100%;
            }

            & h3 {
                font-family: @font-family-handwriting;
                font-size: 54px;
                color: @pink;
                margin-bottom: -20px;
                margin-top: -15px;
            }

            & h4 {
                font-weight: bold;
                font-style: italic;
                font-size: 24px;
                margin: 0;
            }

            .beforePrice,
            .afterPrice {
                padding-top: 5px;
                font-weight: bold;
            }

            .price {
                margin-top: 0;
            }
        }

        & .bread-box-offer {
            border-radius: 30px 40px 32px 40px;
            padding: 15px;
            margin-bottom: 40px;
            color: @brown;
            border: 10px solid #F6F3EC;

            & img {
                max-width: 100%;
            }

            & h3 {
                font-family: @font-family-handwriting;
                font-size: 54px;
                color: @pink;
                margin-bottom: -20px;
                margin-top: -15px;
            }

            & h4 {
                font-weight: bold;
                font-style: italic;
                font-size: 24px;
                margin: 0;
            }

            .beforePrice,
            .afterPrice {
                padding-top: 5px;
                font-weight: bold;
            }

            .price {
                margin-top: 0;
            }
        }
    }

    .product {
        font-style: italic;
        margin-bottom: 50px;

        & .product-image {

            & img {
                max-width: 100%;
            }
        }

        h3 {
            color: @pink;
            font-family: @font-family-handwriting;
            font-size: 25px;
            font-style: normal;
            min-height: 2.2em;
        }

        hr {
            background: url(../content/images/product-hr.png) no-repeat bottom center;
            border: 0;
            height: 2px;
            text-align: center;
            margin-top: 5px;
            margin-bottom: 10px;
        }

        .price {
            text-align: right;
            font-weight: bold;
            margin-top: 5px;

            .price-integer {
                position: relative;
                padding: 0;
                cursor: default;
                text-decoration: none;
                display: inline-block;
                font-size: 22px;
            }

            .price-dot {
                position: relative;
                padding: 0;
                cursor: default;
                text-decoration: none;
                display: inline-block;
                font-size: 22px;
            }

            .price-fractional {
                position: relative;
                top: -8px;
                right: 10px;
                font-size: 12px;
            }
        }

        .price-description {
            float: left;
            line-height: 30px;
            margin-top: 5px;
            margin-left: 10px;
        }
    }
}


.content-right {
    & a:hover {
        text-decoration: none;
    }
}
