.toast-container {
    position: relative;
    top: -70px;
    z-index: -100;

    .toast {
        color: #3c763d;
        background-color: #dff0d8;
        border-color: #d6e9c6;
        padding: 10px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        /*-webkit-animation-name: bounceInDown;
        animation-name: bounceInDown;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;*/
        -webkit-animation: bounceInDown 1s, byebye 1s 400s forwards;
        animation: bounceInDown 1s, byebye 1s 400s forwards;
    }
}

.bounceInDown {
    -webkit-animation-name: bounceInDown;
    animation-name: bounceInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes byebye {
    0%, 100% {
        -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    0% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }

    100% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0);
        transform: matrix3d(0, -3000px, 0);
    }
}

@keyframes byebye {
    0%, 100% {
        -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    0% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }

    100% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0);
        transform: matrix3d(0, -3000px, 0);
    }
}

@-webkit-keyframes bounceInDown {
    0%, 60%, 75%, 90%, 100% {
        -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0);
        transform: translate3d(0, -3000px, 0);
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0);
        transform: translate3d(0, 25px, 0);
    }

    75% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
    }

    90% {
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0);
    }

    100% {
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes bounceInDown {
    0%, 60%, 75%, 90%, 100% {
        -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0);
        transform: translate3d(0, -3000px, 0);
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0);
        transform: translate3d(0, 25px, 0);
    }

    75% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
    }

    90% {
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0);
    }

    100% {
        -webkit-transform: none;
        transform: none;
    }
}
