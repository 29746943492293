#orderChart,
#revenueChart {
    .ct-series-a {
        .ct-point,
        .ct-line,
        .ct-slice-donut {
            stroke: @pink;
        }

        .ct-area {
            fill: @pink;
        }
    }

    .ct-series-b {
        .ct-point,
        .ct-line {
            stroke: @pink;
            stroke-dasharray: 4;
        }

        .ct-area {
            fill: @pink;
        }
    }
}

#deliveriesPerDayChart {
    .ct-series-a .ct-bar {
        stroke: lighten(@brown, 30%);
    }

    .ct-series-b .ct-bar {
        stroke: lighten(@brown, 40%);
    }

    .ct-series-c .ct-bar {
        stroke: lighten(@brown, 50%);
    }
}

.invoicedChart {
    .ct-series-a {
        .ct-slice-donut {
            stroke: @pink;
        }
    }

    .ct-series-b {
        .ct-slice-donut {
            stroke: @pink;
            stroke-opacity: 0.1;
        }
    }
}

.ct-tooltip-a,
.ct-tooltip-b,
.ct-tooltip-c {
    p {
        padding: 5px 10px;
        background: rgba(245,245,245, 1.0);
        border-radius: 4px;
        font-weight: bold;
        font-style: italic;
        border: #f0f0ed solid 1px;
    }
}

.customer-detail {
    .statistics {
        list-style-type: none;
        padding: 0;
    }
}

#invoiceStatistics {
    display: inline-block;
    background: #F5F5F5;
    padding: 15px;
    border-radius: 4px;
    border: #ccc solid 1px;

    .statistics {
        margin-top: 15px;

        li {
            background: #fff;
            border: 1px solid #ccc;
            position: relative;
            margin-bottom: 5px;

            .legend {
                background: @pink;
                width: 10px;
                height: 51px;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
}
