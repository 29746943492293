/// <reference path="bootstrap.less" />
header {
    & .header-top {

        & a.button-top {
            display: block;
            background: url(../Content/images/bg-button-top.png) no-repeat;
            color: #fff;
            width: 252px;
            height: 45px;
            padding-top: 8px;
            text-decoration: none;
            font-style: italic;
            font-weight: bold;
            font-size: 16px;
            margin-right: 15px;

            &:hover {
                text-shadow: 0 0 5px #ccc;
            }

            &.brown {
                background: url(../Content/images/bg-button-top-brown.png) no-repeat;

                &:hover {
                    text-shadow: 0 0 5px #fff;
                }

                @media(max-width: @screen-sm-max) {
                    background: @brown;
                    border-radius: 26px 25px 17px 23px;
                    margin-bottom: 15px; 
                    margin-right: 15px;           
                }

                @media(max-width: @screen-xs-max) {
                    width: 100%;
                    margin-right: inherit;
                }
            }

            @media(max-width: @screen-sm-max) {
                background: @pink;
                border-radius: 26px 25px 17px 23px;
                margin-bottom: 15px;
                margin-right: inherit;
            }

            @media(max-width: @screen-xs-max) {
                width: 100%;
            }
        }

        & .logo {
            margin-bottom: 15px;

            @media(max-width: @screen-sm-max) {
                text-align: center;
            }

            .backoffice & img {
                max-height: 120px;
            }
        }
    }

    & .header-bottom {
        border-bottom: 18px solid @pink;
        background: url(../Content/images/bg-wood-brown.jpg);
        height: 300px;

        @media(max-width: @screen-sm-max) {
            height: inherit;
        }
    }

    & .header-bottom.dashboard {
        border-bottom: 0;
        background: url(../Content/images/bg-wood-white-dashboard.jpg);
        height: 170px;
        z-index: 30;
    }


    h3 {
        font-family: @font-family-handwriting;
    }

    & .login-header {
        background: #fff;
        background: rgba(93,56,36, 0.65);
        padding: 22px 30px;
        color: #fff;
        font-size: 18px;
        font-style: italic;
        position: relative;
        z-index: 1;

        & h3 {
            font-size: 52px;
            font-style: normal;

            @media (max-width: @screen-md-max) {
                font-size: 36px;
            }

            @media (max-width: @grid-float-breakpoint-max) {
                font-size: 36px;
            }
        }

        @media(max-width: @screen-md-max) {
            padding: 31px 30px;
        }
    }

    .video-container {
        display: block;
        position: absolute;
        right: 0;
        top: 60px;
        z-index: 100;

        @media(max-width: @screen-sm-max) {
            top: 43px;
        }

        @media(max-width: @screen-xs-max) {
            position: relative;
            text-align: center;
            top: inherit;
            margin-top: 30px;
        }
    }


    & .bread-header {
        position: absolute;
        top: -43px;
        right: 20px;
    }

    & .bread-header.home {
        position: relative;
        top: -42px;
        right: 10px;
    }

    & .postal-code-check {
        width: 402px;
        height: 278px;
        background: url(../Content/images/bg-postal-code.png) no-repeat;
        padding: 30px;
        position: absolute;
        color: #fff;
        font-style: italic;
        font-size: 22px;
        top: 175px;

        @media (max-width: @screen-sm-max) {
            background: @pink;
            border-radius: 26px 25px 17px 23px;
            position: relative;
            top: inherit;
            height: auto;
            margin: 15px 0;
            padding: 15px;

            .form-control {
                margin-bottom: 15px;
            }
        }

        @media (max-width: @screen-xs-max) {
            width: 100%;
        }

        & h3 {
            font-family: @font-family-sans-serif;
            color: #fff;
            font-weight: bold;
            font-size: 32px;

            & span {
                color: @brown;
            }
        }

        & .btn-search {
            font-weight: bold;
            font-size: 24px;
            background: url(../Content/images/btn-search.png) no-repeat;
            width: 131px;
            padding: 0 0 6px;

            &:hover {
                color: #fff;
                background-position: 0 -50px;
            }
        }

        @media(max-width: @screen-xs-max) {
            position: relative;
        }
    }
}
