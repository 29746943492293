/// <reference path="../bootstrap.less" />

.datepicker {
    th.dow {
        background-color: @pink;
        border-radius: 0;
        color: #fff;
    }

    .day {
        color: @pink;
    }

    .active {
        background-color: @pink !important;
        color: #fff !important;
    }

    .datepicker-days {
        &:before {
            position: absolute;
            left: 0;
            top: 40px;
            content: "";
            background-color: @pink;
            width: 6px;
            height: 30px;
        }

        &:after {
            position: absolute;
            right: 0;
            top: 40px;
            content: "";
            background-color: @pink;
            width: 6px;
            height: 30px;
        }
    }

    table {
        border-spacing: 0 2px;
        border-collapse: separate;
        /*margin-left: 6px;
        margin-right: 6px;*/
    }
}
