/// <reference path="bootstrap.less" />
.delivery-bakery {
        background: url(../Content/images/bg-wood-white.jpg);
        padding: 50px 0;

        & h1 {
            color: @brown;
            font-family: @font-family-handwriting;
            font-size: 50px;

            @media (max-width: @screen-xs-max) {
                font-size: 35px;
            }
        }

        & p {
            font-style: italic;
        }

        & a {
            color: @brown;

            &:hover {
                color: @pink;
            }
        }
}