/// <reference path="bootstrap.less" />
//
// Buttons
// --------------------------------------------------


// Base styles
// --------------------------------------------------

.btn {
    display: inline-block;
    margin-bottom: 0; // For input.btn
    font-weight: @btn-font-weight;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 1px solid transparent;
    white-space: nowrap;
    .button-size(@padding-base-vertical; @padding-base-horizontal; @font-size-base; @line-height-base; @btn-border-radius-base);
    .user-select(none);

    &,
    &:active,
    &.active {
        &:focus,
        &.focus {
            .tab-focus();
        }
    }

    &:hover,
    &:focus,
    &.focus {
        color: #fff;
        text-decoration: none;
        outline: none;
    }

    &:active,
    &.active {
        outline: 0;
        color: #fff;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        cursor: @cursor-disabled;
        .opacity(.65);
        .box-shadow(none);
    }

    a& {
        &.disabled,
        fieldset[disabled] & {
            pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
        }
    }
}


// Alternate buttons
// --------------------------------------------------

.btn-default {
    .button-variant(@btn-default-color; @btn-default-bg; @btn-default-border);
}

.btn-primary {
    .button-variant(@btn-primary-color; @btn-primary-bg; @btn-primary-border);
}
// Success appears as green
.btn-success {
    .button-variant(@btn-success-color; @btn-success-bg; @btn-success-border);
}
// Info appears as blue-green
.btn-info {
    .button-variant(@btn-info-color; @btn-info-bg; @btn-info-border);
}
// Warning appears as orange
.btn-warning {
    .button-variant(@btn-warning-color; @btn-warning-bg; @btn-warning-border);
}
// Danger and error appear as red
.btn-danger {
    .button-variant(@btn-danger-color; @btn-danger-bg; @btn-danger-border);
}


//Normal pink and brown buttons
.btn-pink-normal {
    .button-variant(@btn-pink-color; @btn-pink-bg; @btn-pink-border);
}
.btn-pink-outline {
    .button-variant(@btn-pink-bg; @btn-pink-color; @btn-pink-border);
}
.btn-brown-normal {
    .button-variant(@btn-brown-color; @btn-brown-bg; @btn-brown-border);
}


// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
    color: @link-color;
    font-weight: normal;
    border-radius: 0;

    &,
    &:active,
    &.active,
    &[disabled],
    fieldset[disabled] & {
        background-color: transparent;
        .box-shadow(none);
    }

    &,
    &:hover,
    &:focus,
    &:active {
        border-color: transparent;
    }

    &:hover,
    &:focus {
        color: @link-hover-color;
        text-decoration: @link-hover-decoration;
        background-color: transparent;
    }

    &[disabled],
    fieldset[disabled] & {
        &:hover,
        &:focus {
            color: @btn-link-disabled-color;
            text-decoration: none;
        }
    }
}


// Button Sizes
// --------------------------------------------------

.btn-lg {
    // line-height: ensure even-numbered height of button next to large input
    .button-size(@padding-large-vertical; @padding-large-horizontal; @font-size-large; @line-height-large; @btn-border-radius-large);
}

.btn-sm {
    // line-height: ensure proper height of button next to small input
    .button-size(@padding-small-vertical; @padding-small-horizontal; @font-size-small; @line-height-small; @btn-border-radius-small);
}

.btn-xs {
    .button-size(@padding-xs-vertical; @padding-xs-horizontal; @font-size-small; @line-height-small; @btn-border-radius-small);
}


// Block button
// --------------------------------------------------

.btn-block {
    display: block;
    width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
    margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
    &.btn-block {
        width: 100%;
    }
}


.btn-brown {
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    background: url(../Content/images/buttons.png) no-repeat;
    width: 227px;
    height: 40px;
    line-height: 25px;

    &:hover {
        background: url(../Content/images/buttons.png) no-repeat 0 -50px;
        color: #fff;
    }
}

.two-lines {
    line-height: 14px;
}


.btn-pink-large {
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    background: url(../Content/images/buttons.png) no-repeat 0 -100px;
    width: 227px;
    height: 40px;
    line-height: 25px;

    &:hover {
        background: url(../Content/images/buttons.png) no-repeat 0 -150px;
        color: #fff;
    }
}

.btn-brown-xxl {
    color: #fff;
    line-height: 35px;
    font-weight: bold;
    font-size: 14px;
    background: url(../Content/images/btn-xxl.png) 0 0 no-repeat;
    width: 400px;
    height: 56px;

    &:hover {
        background-position: 0 -68px;
    }
}

.btn-pink-xxl {
    color: #fff;
    line-height: 35px;
    font-weight: bold;
    font-size: 14px;
    background: url(../Content/images/btn-xxl.png) 0 -137px no-repeat;
    width: 400px;
    height: 56px;

    &:hover {
        background-position: 0 -205px;
    }
}

.btn-brown-small {
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    background: url(../Content/images/btn-brown-small.png) no-repeat;
    width: 120px;
    height: 28px;
    line-height: 12px;

    &:hover {
        background: url(../Content/images/btn-brown-small.png) no-repeat 0 -35px;
        color: #fff;
    }
}

.btn-pink {
    color: #fff;
    font-weight: normal;
    font-style: italic;
    font-size: 14px;
    background: url(../Content/images/buttons-small.png) no-repeat 0 -77px;
    width: 144px;
    height: 40px;
    line-height: 25px;

    &:hover {
        background: url(../Content/images/buttons-small.png) no-repeat 0 -118px;
        color: #fff;
    }
}

& .btn-sm {
    background-image: url(../Content/images/buttons-small.png);
    background-color: transparent;
    background-position: 0 0;
    border: 0 none;
    color: #fff;
    font-style: italic;
    width: 140px;
    height: 32px;
    font-size: 16px;
    padding: 0;
    line-height: 30px;
    outline: 0;

    &:hover {
        background-position: 0 -41px;
    }

    &.purple {
        background-position: 0 -81px;

        &:hover {
            background-position: 0 -122px;
        }
    }

    @media (max-width: @screen-sm-max) {
        margin-bottom: 5px;
    }
}


.btn-backoffice-cancel,
.btn-backoffice-confirm {
    color: #fff;
    font-weight: normal;
    font-size: 14px;
    min-width: 125px;
    height: 35px;
    border-radius: 25px;
}

.btn-backoffice-confirm {
    background-color: @pink;

    &:hover {
        background-color: darken(@pink, 10%);
    }
}

.btn-backoffice-cancel {
    background-color: @gray;

    &:hover {
        background-color: darken(@gray, 10%);
    }
}

.btn-slider {
    // @btn-pink-color; @btn-pink-bg; @btn-pink-border
    border: 1px solid @btn-pink-border;
    height: 36px;
    border-radius: 4px;
    /*.btn:first-child {
        left: 0;
    }
    .btn:last-child {
        right: 0;
    }*/
    .btn {
        display: block;
        width: 160px;
        float: left;
        border-radius: 0px;

        &:hover {
            color: @btn-pink-bg;
        }

        .transition(.2s ease-in-out);
    }

    .btn-slider-active {
        background-color: @btn-pink-bg;
        color: @btn-pink-color;

        &:hover {
            color: @btn-pink-color;
        }
    }
}

button,
a {

    &:focus {
        outline:0;
    }
}