.border-box {
    padding: 15px;
    border: 10px solid #F6F3EC;

    &.border-box-solid {
        background-color: #F6F3EC;
    }
}

hr.pink {
    border: @pink 1px solid;
}

hr.gray {
    border: #ddd 1px solid;
}

.backoffice {
    a.brown {
        color: @brown;

        i {
            color: @pink;
        }
    }
}

.side-menu {
    a {
        display: block;
        color: @text-color;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ccc;

        &:last-of-type {
            border: 0 none;
        }

        &:hover {
            text-decoration: none;
            color: @pink;
        }

        i {
            margin-right: 10px;
            color: @pink;
        }
    }
}


.customer-detail {
    margin-top: 20px;

    .form-group {
        margin-bottom: 5px;
    }
}

.commentary {
    padding: 15px;
    background: #f9f9f9;
    border: 1px solid #ccc;
    font-style: italic;
    font-size: 13px;
    border-radius: @border-radius-base;
}

.cell-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.cell-clickable {
    position: relative;
}

.form-group > span {
    white-space: pre-wrap;
}

p.note {
    margin-top: 6px;
    padding: 0 1px;
    font-size: 11px;
    line-height: 15px;
    color: #999;
}


.delivery-calendar {
    th {
        width: 14%;
        text-align: center;
    }

    td {
        text-align: center;
    }

    td.cell-today {
        font-weight: bold;
    }

    td.cell-past {
        color: #ddd;
    }

    td.cell-order-recurring,
    td.cell-order-single {
        color: #fff;
        /*cursor: pointer;*/

        span {
            display: inline-block;
            border-radius: 5px;
            padding: 5px;
            margin: -5px;
            /*width: 100%;
            height: 100%;*/
        }
        /* Recurring orders */
        &.cell-order-recurring span {
            background-color: fade(@pink, 80%);
        }

        &.cell-order-recurring.cell-past span {
            background-color: fade(@pink, 20%);
        }
        /* single orders */
        &.cell-order-single span {
            background-color: fade(@brown, 80%);
        }

        &.cell-order-single.cell-past span {
            background-color: fade(@brown, 20%);
        }
        /* Recurring and single orders on a single day */
        &.cell-order-recurring.cell-order-single span {
            background: linear-gradient(125deg, fade(@pink, 80%) 50%, fade(@brown, 80%) 50%);
        }

        &.cell-order-recurring.cell-order-single.cell-past span {
            background: linear-gradient(125deg, fade(@pink, 20%) 50%, fade(@brown, 20%) 50%);
        }
    }
}
