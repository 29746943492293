/// <reference path="bootstrap.less" />

.validation-summary-errors {
    background-color: #F6F3EC;
    padding: 10px;
    margin-bottom: 15px;
    font-weight: bold;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            padding: 0;
            margin: 0;

            &:before {
                .fa();
                content: @fa-var-exclamation-circle;
                margin-right: 4px;
            }
        }
    }
}
