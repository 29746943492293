.contact-info {
    background: url(../Content/images/bg-postal-code.png) no-repeat;
    background-size: 100%;
    padding: 15px;
    color: #fff;
    line-height: 24px;


    h4 {
        font-size: 32px;
        font-style: italic;
        font-weight: bold;
        margin-bottom:  15px;
    }

    a {
        color: #fff;
    }
}
