/// <reference path="bootstrap.less" />
span.success {
    color: #77BB0E;
    padding: 5px 10px;

    &:before {
        content: "\f00c";
        font-family: FontAwesome;
        padding-right: 8px;
    }
}

span.error {
    color: #d43f3a;
    padding: 5px 10px;

    &:before {
        content: "\f00d";
        font-family: FontAwesome;
        padding-right: 8px;
    }
}
