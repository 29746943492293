/// <reference path="bootstrap.less" />
.quote {
    & .quote-top {
        background: @pink url('../Content/images/icons.png') repeat;
        padding: 45px 0;
        color: #fff;
        font-size: 24px;
        font-style: italic;

        & h3 {
            font-size: 34px;
            font-weight: bold;
            font-style: italic;
            color: #fff;
        }
    }

    & .quote-bottom {
        height: 30px;
        background: url('../Content/images/icons.png') repeat;
    }
}
