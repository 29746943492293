/// <reference path="bootstrap.less" />
.checkbox {
    input[type="checkbox"] {
        display: none;
    }

    label {
        padding-left: 0;
    }

    input[type="checkbox"] + label span {
        display: inline-block;
        float: left;
        width: 22px;
        height: 22px;
        margin: -1px 4px 0 0;
        vertical-align: middle;
        border: 2px solid #787878;
        cursor: pointer;
        border-radius: 6px;
    }

    input[type="checkbox"]:checked + label span {
        background: url("/Content/images/checked.png") center center no-repeat;
    }
}

.checkbox-disabled {
    display: inline-block;
    float: left;
    width: 22px;
    height: 22px;
    margin: -1px 4px 0 0;
    vertical-align: middle;
    border: 2px solid #ddd;
    border-radius: 6px;
}


.radio {
    input[type="radio"] {
        display: none;
    }

    label {
        padding-left: 0;
    }

    input[type="radio"] + label span {
        display: inline-block;
        float: left;
        width: 22px;
        height: 22px;
        margin: -1px 4px 0 0;
        vertical-align: middle;
        border: 2px solid #787878;
        cursor: pointer;
        border-radius: 50%;
    }

    input[type="radio"]:checked + label span {
        background: url("/Content/images/circle.png") center center no-repeat;
    }
}
