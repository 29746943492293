/// <reference path="bootstrap.less" />
.cart-popup {
    background: @white;
    color: @black;
    padding: 0;
    font-style: italic;
    border-radius: 30px;

    ul {
        padding-left: 0;

        li {
            list-style: none;
            border-radius: 30px 40px 32px 40px;
            padding: 2px 5px;

            & a {
                &:hover {
                    text-decoration: none;
                    color: @pink;
                }
            }
        }

        &.products {
            li {
                &:nth-child(even) {
                    background: #f5f5f5;
                }
            }
        }
    }

    hr {
        background: url(../Content/images/border-brown.png) repeat-x;
        height: 10px;
        border: 0;
        opacity: 0.2;
    }

    hr.pink {
        background: url(../Content/images/border-pink.png) repeat-x;
        opacity: 1;
    }

    .product-count {
        display: inline-block;
        width: 20px;
        text-align: center;
    }

    h3 {
        font-weight: bold;
        background: @pink;
        color: #fff;
        padding: 15px;
        border-radius: 30px 30px 0 0;
    }


    & .modal-body {
        padding: 0;

        .fa-remove.close {
            padding: 17px;
            opacity: 1;
        }
    }
}

.popup-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(180, 180, 180, 0.5);
    text-align: center;
    font-style: normal;
    display: none;

    > div {
        bottom: -100%;
        position: absolute;
        width: 100%;
        background-color: #fff;
        padding: 20px;
        text-align: left;
        border-top: 2px solid #999;
        padding-bottom: 30px;
        box-shadow: 0px -1px 5px 0px rgba(50, 50, 50, 0.4);

        .transition(~"bottom 0.3s ease-out");
    }
}
